import {
    GC_GET_GAME_RESULTS,
    GC_GET_SCENARIO_COMPLETION,
    GC_GET_NEW_USERS_PER_WEEK,
    GC_GET_USER_ACTIVITY,
    GC_GET_AVERAGE_CO_TIME,
    GC_GET_FINISHED_USERS,
} from '@/graphql/logs'
import apollo from '@/apolloClient'

function checkForMissingMonths(from, to, logs, data) {
    const sortedMonths = Object.keys(data).map((k) => parseInt(k, 10)).sort()

    // Check for missing months (no data or zero)
    if (sortedMonths.length > 0) {
        // Get start date
        const firstDay = (!from || from.getTime() <= 0 ? new Date(sortedMonths[0]) : from)
        firstDay.setUTCHours(0, 0, 0, 0)
        firstDay.setUTCDate(1)

        // Get end date
        const lastDay = (!to || to.getTime() <= 0 ? new Date(sortedMonths[sortedMonths.length - 1]) : to)
        lastDay.setUTCHours(0, 0, 0, 0)
        lastDay.setUTCDate(1)

        // Get all monthIDs and add missing ones
        const monthCount = Math.abs(lastDay.getUTCMonth() - firstDay.getUTCMonth())

        let currentDate = firstDay

        for (var i = 0; i < monthCount; i++) {
            // Check if current month value is missing
            const monthID = currentDate.getTime()

            if (!data[monthID]) {
                data[monthID] = 0
            }

            // Move to next month
            currentDate.setUTCMonth(currentDate.getUTCMonth() + 1)
        }
    }

    return data
}

function checkForMissingWeeks(from, to, logs, data) {
    const sortedWeeks = Object.keys(data).map((k) => parseInt(k, 10)).sort()
    
    // Check for missing weeks (no data or zero)
    if (sortedWeeks.length > 0) {
        // Get start date
        let firstMonday = (!from || from.getTime() <= 0 ? new Date(sortedWeeks[0]) : from)
        firstMonday.setUTCHours(0, 0, 0, 0)
        firstMonday.setUTCDate(firstMonday.getUTCDate() - ((6 + firstMonday.getUTCDay()) % 7))

        // Get all weekIDs and add missing ones
        const oneWeek = (7 * 24 * 60 * 60 * 1000)
        const weekCount = Math.round((to.getTime() - firstMonday.getTime()) / oneWeek)

        let currentDate = firstMonday
        for (var i = 0; i < weekCount; i++) {
            let weekID = currentDate.getTime()
            
            if (!data[weekID]) {
                data[weekID] = 0
            }

            // Move to next week
            currentDate = new Date(currentDate.getTime() + oneWeek)
        }
    }

    return data
}

export default {
    namespaced: true,
    state: {
        scenarios: {},
        gameNamesByID: {},
        months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
        'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        totalUsers: 0
    },
    actions: {
        SetTotalUsers({state}, total){
            state.totalUsers = total
        },
        SetScenarios({state}, scenarios){
            state.scenarios = scenarios
        },
        SetGameNamesByID({state}, gameNamesByID){
            state.gameNamesByID = gameNamesByID
        },
        async GetGameResults({state}, filters){
            const response = await apollo.query({
                query: GC_GET_GAME_RESULTS,
                variables: {
                    companyComp: filters.companyComp,
                    fromComp: filters.fromComp,
                    toComp: filters.toComp,
                    xpComp: filters.xpComp,
                    mailingComp: filters.mailingComp,
                    courseComp: filters.courseComp,
                    otherComp: filters.otherComp,
                    seminarComp: filters.seminarComp,
                    seniorityComp: filters.seniorityComp,
                    statusComp: filters.statusComp,
                    idComp: (filters.idComp || {})
                }
            })
            if (response && response.data.users){
                let retGameResults = {}
                let retGameResultAverageRaw = {}
                let xlsData = {
                    scenario: {},
                    game: {}
                }
                let totalResultAverage = 0

                const users = response.data.users
                let averageTryPerGame = {}
                let exercicesPerScenario = {}

                let sumPerUser = {}
                let tryPerUser = {}

                users.forEach(user => {
                    //Sum the score by scenario[user][game]
                    user.logs.forEach(log => {
                        let gameName = state.gameNamesByID[log.data_key];
                        if (state.scenarios[log.data.scenario_id] && gameName && state.scenarios[log.data.scenario_id].stats && state.scenarios[log.data.scenario_id].stats.game){
                            let scenarioID = log.data.scenario_id
                            if (!exercicesPerScenario[scenarioID]) { exercicesPerScenario[scenarioID] = {} }
                            if (!averageTryPerGame[scenarioID]) { averageTryPerGame[scenarioID] = {} }
                            if (!averageTryPerGame[scenarioID][user.id]) { averageTryPerGame[scenarioID][user.id] = {} }
                            if (!averageTryPerGame[scenarioID][user.id][gameName]) { averageTryPerGame[scenarioID][user.id][gameName] = 0 }
                            if (!exercicesPerScenario[scenarioID][user.id]) { exercicesPerScenario[scenarioID][user.id] = {} }
                            if (!exercicesPerScenario[scenarioID][user.id][gameName]) { exercicesPerScenario[scenarioID][user.id][gameName] = 0 }
                            if (!tryPerUser[user.id]) { tryPerUser[user.id] = 0 }
                            if (!sumPerUser[user.id]) { sumPerUser[user.id] = 0 }
                            exercicesPerScenario[scenarioID][user.id][gameName] += log.data.value
                            averageTryPerGame[scenarioID][user.id][gameName] += 1
                            sumPerUser[user.id] += log.data.value
                            tryPerUser[user.id]++
                        }
                    })
                })

                let numScenario = 0
                //Do the average with all users
                for (const scenarioID in exercicesPerScenario) {
                    if (Object.prototype.hasOwnProperty.call(exercicesPerScenario, scenarioID)) {
                        //Format for csv
                        const users = exercicesPerScenario[scenarioID]
                        numScenario++
                        let label = state.scenarios[scenarioID].name
                        if (!xlsData.game[label]) xlsData.game[label] = {}
                        if (!xlsData.scenario[label]) xlsData.scenario[label] = {}
                        if (!retGameResults[label]) { retGameResults[label] = {} }
                        for (const userID in users) {
                            if (Object.prototype.hasOwnProperty.call(users, userID)) {
                                const games = users[userID];
                                
                                //Sum per game
                                for (const gameName in games) {
                                    if (Object.prototype.hasOwnProperty.call(games, gameName)) {
                                        if (!retGameResults[label][gameName]) { retGameResults[label][gameName] = 0 }
                                        const gameResult = games[gameName];
                                        let result = gameResult / averageTryPerGame[scenarioID][userID][gameName]

                                        retGameResults[label][gameName] += result
                                    }
                                }
                            }
                        }
                        //Do the average per game
                        let sum = 0
                        let num = 0
                        for (const gameName in retGameResults[label]) {
                            if (Object.prototype.hasOwnProperty.call(retGameResults[label], gameName)) {
                                const sumOfResult = retGameResults[label][gameName];

                                retGameResults[label][gameName] = Math.round(((sumOfResult / Object.keys(users).length) * 100))
                                sum += retGameResults[label][gameName]
                                num++
                                xlsData.game[label][gameName] = retGameResults[label][gameName]
                            }
                        }
                        retGameResultAverageRaw[label] = Math.round((sum / num))
                        totalResultAverage += retGameResultAverageRaw[label]
                    }
                }
                return {
                    dashboard: {
                        gameResults: retGameResults,
                        gameResultAverageRaw: retGameResultAverageRaw,
                        totalResultAverage: totalResultAverage / numScenario
                    },
                    xls: {
                        sumPerUser,
                        tryPerUser
                    }
                }
            }
            return {}
        },
        async GetScenarioCompletion({state}, filters){
            const response = await apollo.query({
                query: GC_GET_SCENARIO_COMPLETION,
                variables: {
                    companyComp: filters.companyComp,
                    fromComp: filters.fromComp,
                    toComp: filters.toComp,
                    xpComp: filters.xpComp,
                    mailingComp: filters.mailingComp,
                    courseComp: filters.courseComp,
                    otherComp: filters.otherComp,
                    seminarComp: filters.seminarComp,
                    seniorityComp: filters.seniorityComp,
                    statusComp: filters.statusComp,
                    idComp: (filters.idComp || {})
                }
            })
            if (response && response.data.users){

                const scenarioNodesPerUser = response.data.users;
                let scenarioCompletionFormat = {}
                let scenarioCompletionPre = {}
                let averagePdf = {}
                let totalScenarioCompletion = 0
                let videoPerScenario = {}

                let tryPerUser = {}
                let progressPerUser = {}

                //Format for each scenario
                scenarioNodesPerUser.forEach(user => {
                    user.logs.forEach(log => {
                        if (state.scenarios[log.data_key] && log.data.stats){
                            if (!scenarioCompletionFormat[log.data_key]){
                                scenarioCompletionFormat[log.data_key] = {}
                            }
                            if (!scenarioCompletionFormat[log.data_key][user.id]){
                                scenarioCompletionFormat[log.data_key][user.id] = {
                                    progress: 0,
                                    progressPDF: 0,
                                    progressMedia: 0
                                }
                            }
                            state.scenarios[log.data_key].stats = log.data.stats
                            //Get the better progress per scenario per user
                            if (scenarioCompletionFormat[log.data_key][user.id].progress < log.data.progress){
                                scenarioCompletionFormat[log.data_key][user.id].progress = log.data.progress
                            }
                            if (scenarioCompletionFormat[log.data_key][user.id].progressPDF < log.data.progressPDF){
                                scenarioCompletionFormat[log.data_key][user.id].progressPDF = log.data.progressPDF
                            }
                            if (scenarioCompletionFormat[log.data_key][user.id].progressMedia < log.data.progressMedia){
                                scenarioCompletionFormat[log.data_key][user.id].progressMedia = log.data.progressMedia
                            }
                        }
                    })
                })

                let formatedScenarioXls = {}
                for (const scenarioID in scenarioCompletionFormat) {
                    if (Object.prototype.hasOwnProperty.call(scenarioCompletionFormat, scenarioID)) {
                        const scenario = scenarioCompletionFormat[scenarioID]
                        let progress = 0
                        let progressPDF = 0
                        let progressMedia = 0
                        let totalUsers = Object.keys(scenario).length

                        for (const userID in scenario) {
                            if (Object.prototype.hasOwnProperty.call(scenario, userID)) {
                                const user = scenario[userID];

                                if (!progressPerUser[userID]) { progressPerUser[userID] = 0}
                                if (!tryPerUser[userID]) { tryPerUser[userID] = 0}
                                
                                progressPerUser[userID] += Math.min(user.progress, 1)
                                tryPerUser[userID] += 1

                                progress += Math.min(user.progress, 1)
                                progressPDF += Math.min(user.progressPDF, 1)
                                progressMedia += Math.min(user.progressMedia, 1)
                            }
                        }

                        scenarioCompletionPre[scenarioID] = Math.round(((progress / totalUsers) * 100))
                        totalScenarioCompletion += scenarioCompletionPre[scenarioID]
                        averagePdf[scenarioID] = Math.round(((progressPDF / totalUsers) * 100))
                        videoPerScenario[scenarioID] = Math.round(((progressMedia / totalUsers) * 100))

                        if (!formatedScenarioXls[state.scenarios[scenarioID].name]) formatedScenarioXls[state.scenarios[scenarioID].name] = {}

                        formatedScenarioXls[state.scenarios[scenarioID].name].completion = scenarioCompletionPre[scenarioID];
                        formatedScenarioXls[state.scenarios[scenarioID].name]['PDF Visionnés'] = averagePdf[scenarioID]
                        formatedScenarioXls[state.scenarios[scenarioID].name]['Vidéos vues en entier'] = videoPerScenario[scenarioID]
                    }
                }

                return {
                    dashboard: {
                        averagePdf,
                        videoPerScenario,
                        scenarioCompletionPre,
                        totalScenarioCompletion: totalScenarioCompletion / Object.keys(state.scenarios).length
                    },
                    xls: {
                        scenarioData: formatedScenarioXls,
                        tryPerUser,
                        progressPerUser
                    }
                }
            }
            return {}
        },
        async GetActiveUsersPerMonth(context, filters) {
            // Get TO date as first day of next month
            let to = new Date(filters.to ? filters.to : Date.now())

            to.setUTCHours(0, 0, 0, 0)
            to.setUTCDate(1)
            to.setUTCMonth(to.getUTCMonth() + 1)

            // Get FROM time, equivalent to X months since first day of the month
            let from = new Date();

            if (!filters.from){
                // Load all data available
                from.setTime(0)
            } else {
                from = new Date(filters.from)
                from.setUTCHours(0, 0, 0, 0)
                from.setUTCDate(1)
            }

            // Query logs
            const response = await apollo.query({
                query: GC_GET_USER_ACTIVITY,
                variables: {
                    companyComp: filters.companyComp,
                    from,
                    to,
                    xpComp: filters.xpComp,
                    mailingComp: filters.mailingComp,
                    courseComp: filters.courseComp,
                    otherComp: filters.otherComp,
                    seminarComp: filters.seminarComp,
                    seniorityComp: filters.seniorityComp,
                    statusComp: filters.statusComp,
                    idComp: (filters.idComp || {})
                }
            })

            // Parse user logs
            if (response && response.data.users){
                const users = response.data.users;
                let activeUserPerMonth = {}

                users.forEach(user => {
                    let previousMonth = 0;

                    // User logs are sorted by ascending creation time
                    user.logs.forEach((log) => {
                        // Get month id (UTC time of the first day of the month at 0 hour)
                        const d = new Date(log.created_at)
                        d.setUTCHours(0, 0, 0, 0)
                        d.setUTCDate(1)

                        const monthID = d.getTime()

                        // Init active user count if needed
                        if (!activeUserPerMonth[monthID]) {
                            activeUserPerMonth[monthID] = 0
                        }

                        // Check if we have already marked this user as active during the month
                        if (previousMonth !== monthID) {
                            activeUserPerMonth[monthID] += 1
                        }
                        previousMonth = monthID;
                    })
                })

                activeUserPerMonth = checkForMissingMonths(from, to, users, activeUserPerMonth)

                return activeUserPerMonth
            }

            return 0
        },
        async NewUsersPerMonth(context, filters){
            // Get TO date as first day of next month
            let to = new Date(filters.to ? filters.to : Date.now())

            to.setUTCHours(0, 0, 0, 0)
            to.setUTCDate(1)
            to.setUTCMonth(to.getUTCMonth() + 1)

            // Get FROM time, equivalent to X months since first day of the month
            let from = new Date();

            if (!filters.from){
                // Load all data available
                from.setTime(0)
            } else {
                from = new Date(filters.from)
                from.setUTCHours(0, 0, 0, 0)
                from.setUTCDate(1)
            }

            // Query logs
            const response = await apollo.query({
                query: GC_GET_NEW_USERS_PER_WEEK,
                variables: {
                    companyComp: filters.companyComp,
                    from,
                    to,
                    xpComp: filters.xpComp,
                    mailingComp: filters.mailingComp,
                    courseComp: filters.courseComp,
                    otherComp: filters.otherComp,
                    seminarComp: filters.seminarComp,
                    seniorityComp: filters.seniorityComp,
                    statusComp: filters.statusComp,
                    idComp: (filters.idComp || {})
                }
            })

            // Parse user logs
            if (response && response.data.users){
                const users = response.data.users

                let countPerMonth = {}

                users.forEach(user => {
                    // Get month id (UTC time of the first day of the month at 0 hour)
                    const creationDate = new Date(user.created_at)

                    creationDate.setUTCHours(0, 0, 0, 0)
                    creationDate.setUTCDate(1)

                    let monthID = creationDate.getTime()

                    // Init active user count if needed
                    if (!countPerMonth[monthID]){
                        countPerMonth[monthID] = 0
                    }

                    // Mark this user as created during the month
                    countPerMonth[monthID] += 1
                })

                countPerMonth = checkForMissingMonths(from, to, users, countPerMonth)

                return countPerMonth
            }

            return 0
        },
        async GetFinishedUsers({state}, filters){
            const response = await apollo.query({
                query: GC_GET_FINISHED_USERS,
                variables:{
                    companyComp: filters.companyComp,
                    fromComp: filters.fromComp,
                    toComp: filters.toComp,
                    xpComp: filters.xpComp,
                    mailingComp: filters.mailingComp,
                    courseComp: filters.courseComp,
                    otherComp: filters.otherComp,
                    seminarComp: filters.seminarComp,
                    seniorityComp: filters.seniorityComp,
                    statusComp: filters.statusComp,
                    idComp: (filters.idComp || {})
                }
            })

            if (response.data && response.data.users){
                let scenarioIDS = Object.keys(state.scenarios).sort()
                const users = response.data.users;
                let sumFinished = 0
                let finishedByUser = {}

                users.forEach(user => {
                    if (user.logs && user.logs.length > 0){
                        let uniqueIDS = []
                        user.logs.forEach(log => {
                            if (!uniqueIDS.includes(log.data_key)){
                                uniqueIDS.push(log.data_key)
                            }
                        })
                        let index = 0
                        uniqueIDS.forEach(id => {
                            if (scenarioIDS.includes(id)){
                                index++
                                finishedByUser[user.id] = index
                            }
                        })
                        if (index === scenarioIDS.length){
                            sumFinished++
                        }
                    }
                })

                return {
                    finished: Math.round(((sumFinished / state.totalUsers) * 100)),
                    finishedByUser
                }
            }
            return {}
        },
        async GetAverageCoTime({state}, filters){
            const response = await apollo.query({
                query:GC_GET_AVERAGE_CO_TIME,
                variables:{
                    companyComp: filters.companyComp,
                    fromComp: filters.fromComp,
                    toComp: filters.toComp,
                    xpComp: filters.xpComp,
                    mailingComp: filters.mailingComp,
                    courseComp: filters.courseComp,
                    otherComp: filters.otherComp,
                    seminarComp: filters.seminarComp,
                    seniorityComp: filters.seniorityComp,
                    statusComp: filters.statusComp,
                    idComp: (filters.idComp || {})
                }
            })

            if (response.data && response.data.users){
                let sumOfTime = 0
                let sessionTimePerUser = {}
                let timePerUser = {}
                response.data.users.forEach(user => {
                    if (user.logs){

                        if (!sessionTimePerUser[user.id]) { sessionTimePerUser[user.id] = 0}
                        user.logs.forEach(log => {
                            let created = new Date(log.created_at)
                            let updated = new Date(log.updated_at)
                            if (log.logType.slug == 'scenario_nodes'){ //difference between created and updated times
                                let diff = 0;
                                let createdDiff = updated.getTime() - created.getTime()
                                if (createdDiff >= 200 && createdDiff <= 10800000 ){
                                    diff = createdDiff
                                } else if (createdDiff > 10800000 ){
                                    diff = 10800000
                                }
                                sessionTimePerUser[user.id] += diff
                            }
                            else { //visited media library
                                sessionTimePerUser[user.id] += (1000 * 60)
                            }
                        })
                        timePerUser[user.id] = Math.round(sessionTimePerUser[user.id] / 1000)
                        sumOfTime += sessionTimePerUser[user.id]
                    }
                })

                return {
                    totalUserTime: (sumOfTime / response.data.users.length) / 3600000,
                    timePerUser
                }
            }
            return {}
        },
        async GetAllOfficeData({state, dispatch}, data){
            let ids = data.ids
            let filters = data.filters
            let officeData = {}
            for (const id of ids) {
                if (!officeData[id]) { officeData[id] = {} }
                filters.idComp = { _eq: id }
                let gameResults =   await dispatch('GetGameResults', filters)
                let completion =    await dispatch('GetScenarioCompletion', filters)
                let finishedUsers =      await dispatch('GetFinishedUsers', filters)
                let coTime =        await dispatch('GetAverageCoTime', filters)

                if (gameResults && gameResults.dashboard){
                    officeData[id]['Taux de réussite aux exercices'] = gameResults.dashboard.totalResultAverage ? Math.round(gameResults.dashboard.totalResultAverage) : 0
                }
                if (completion && completion.dashboard){
                    officeData[id]['Completion totale'] = completion.dashboard.totalScenarioCompletion ? Math.round(completion.dashboard.totalScenarioCompletion) : 0
                }
                if (finishedUsers && finishedUsers.finished !== null && finishedUsers.finished !== undefined){
                    officeData[id]['Utilisateurs ayant terminé'] = finishedUsers.finished ? finishedUsers.finished : 0
                }
                if (coTime && coTime.totalUserTime){
                    officeData[id]['Temps de connexion moyen'] = coTime.totalUserTime ? coTime.totalUserTime : 0
                }
            }

            return officeData
        }
    },
}