import gql from "graphql-tag"

//-----------------Initialization-----------------//
const GC_INITALIZE_DASHBOARD_DATA = gql`
	query GetScenarioInfo {
		scenario(
			distinct_on: name
			where: { scenarioType: { slug: { _eq: "scenario" } }, scenarioLocations: {} }
		) {
			name
			id
			nodes_aggregate {
				aggregate {
					count
				}
			}
			scenarioLocations {
				location {
					name
				}
			}
		}
		game {
			id
			title
		}
	}
`

const GC_GET_TOTAL = gql`
	query Total(
		$fromComp: timestamptz_comparison_exp
		$toComp: timestamptz_comparison_exp
		$xpComp: Boolean_comparison_exp
		$statusComp: Int_comparison_exp
		$companyComp: String_comparison_exp
		$seminarComp: Boolean_comparison_exp
		$courseComp: Boolean_comparison_exp
		$otherComp: Boolean_comparison_exp
		$mailingComp: Boolean_comparison_exp
		$seniorityComp: Int_comparison_exp
	) {
		users(
			where: {
				role: { _eq: "user" }
				company: {
					_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
				}
				_and: {
					status: $statusComp
					seniority: $seniorityComp
					experience_seminar: $seminarComp
					experience_course: $courseComp
					experience_other: $otherComp
					mailing: $mailingComp
					logs: { _and: [{ created_at: $fromComp }, { created_at: $toComp }] }
					experienced: $xpComp
					company: $companyComp
				}
			}
		) {
			id
			name
			first_name
			email
			experienced
			status
			position
			company
			seniority
			experience_course
			experience_other
			experience_seminar
			mailing
			userOrganisations {
				organisations {
					name
				}
			}
		}
		user_organisations(
			where: {
				users: {
					role: { _eq: "user" }
					company: {
						_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
					}
					_and: {
						status: $statusComp
						seniority: $seniorityComp
						experience_seminar: $seminarComp
						experience_course: $courseComp
						experience_other: $otherComp
						mailing: $mailingComp
						experienced: $xpComp
						company: $companyComp
					}
				}
				organisations: { _and: [{ created_at: $fromComp }, { created_at: $toComp }] }
			}
		) {
			organisations {
				address
				address2
				city
				country
				name
				post_code
				id
				userCount:userOrganisations_aggregate {
					aggregate {
						count(columns: user)
					}
				}
			}
		}
	}
`

//-----------------Game results-----------------//
const GC_GET_GAME_RESULTS = gql`
	query GameResults(
		$fromComp: timestamptz_comparison_exp
		$toComp: timestamptz_comparison_exp
		$idComp: uuid_comparison_exp
		$xpComp: Boolean_comparison_exp
		$statusComp: Int_comparison_exp
		$companyComp: String_comparison_exp
		$seminarComp: Boolean_comparison_exp
		$courseComp: Boolean_comparison_exp
		$otherComp: Boolean_comparison_exp
		$mailingComp: Boolean_comparison_exp
		$seniorityComp: Int_comparison_exp
	) {
		users(
			where: {
				role: { _eq: "user" }
				company: {
					_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
				},
				_and: {
					status: $statusComp
					seniority: $seniorityComp
					experience_seminar: $seminarComp
					experience_course: $courseComp
					experience_other: $otherComp
					mailing: $mailingComp
					company: $companyComp
					logs: {
						logType: { slug: { _eq: "game_results" } }
						_and: [{ created_at: $fromComp }, { created_at: $toComp }]
					}
					experienced: $xpComp
					userOrganisations: { organisation: $idComp }
				}
			}
		) {
			id
			logs(
				where: {
					logType: { slug: { _eq: "game_results" } }
					_and: [{ created_at: $fromComp }, { created_at: $toComp }]
				}
			) {
				data
				data_key
			}
		}
	}
`

const GC_GET_GAME_RESULTS_BY_USERID = gql`
	query GameResultByUser($id: uuid!, $fromComp: timestamptz_comparison_exp, $toComp: timestamptz_comparison_exp) {
		users(
			where: {
				logs: {
					logType: { slug: { _eq: "game_results" } }
					_and: [{ created_at: $fromComp }, { created_at: $toComp }]
				}
				id: { _eq: $id }
			}
		) {
			id
			logs(where: { logType: { slug: { _eq: "game_results" } } }) {
				data
				data_key
			}
		}
	}
`

// query Total {
// 	users(where: {role: {_eq: "user"}, _or: [
// 	  {company: {_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]}}
// 	  {company: {_eq: null}}
// 	]}) {
// 	  id
// 	  name
// 	}
//   }
  
//-----------------Scenario Complétion-----------------/
const GC_GET_SCENARIO_COMPLETION = gql`
	query ScenarioCompletion(
		$idComp: uuid_comparison_exp
		$fromComp: timestamptz_comparison_exp
		$toComp: timestamptz_comparison_exp
		$xpComp: Boolean_comparison_exp
		$statusComp: Int_comparison_exp
		$companyComp: String_comparison_exp
		$seminarComp: Boolean_comparison_exp
		$courseComp: Boolean_comparison_exp
		$otherComp: Boolean_comparison_exp
		$mailingComp: Boolean_comparison_exp
		$seniorityComp: Int_comparison_exp
	) {
		users(
			where: {
				role: { _eq: "user" }
				company: {
					_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
				},
				_and: {
					status: $statusComp
					seniority: $seniorityComp
					experience_seminar: $seminarComp
					experience_course: $courseComp
					experience_other: $otherComp
					mailing: $mailingComp
					company: $companyComp
					logs: {
						logType: { slug: { _eq: "scenario_nodes" } }
						_and: [{ created_at: $fromComp }, { created_at: $toComp }]
					}
					experienced: $xpComp
					userOrganisations: { organisation: $idComp }
				}
			}
		) {
			id
			logs(
				where: {
					logType: { slug: { _eq: "scenario_nodes" } }
					_and: [{ created_at: $fromComp }, { created_at: $toComp }]
				}
			) {
				data_key
				data
			}
		}
	}
`

const GC_GET_SCENARIO_COMPLETION_BY_USERID = gql`
	query ScenarioCompletionByID($id: uuid!, $fromComp: timestamptz_comparison_exp, $toComp: timestamptz_comparison_exp) {
		users(
			where: {
				logs: {
					logType: { slug: { _eq: "scenario_nodes" } }
					_and: [{ created_at: $fromComp }, { created_at: $toComp }]
				}
				id: { _eq: $id }
			}
		) {
			logs(where: { logType: { slug: { _eq: "scenario_nodes" } } }) {
				created_at
				data
				data_key
			}
		}
	}
`

//-----------------Average Connexion time-----------------//
const GC_GET_AVERAGE_CO_TIME = gql`
	query AverageCoTime(
		$idComp: uuid_comparison_exp
		$fromComp: timestamptz_comparison_exp
		$toComp: timestamptz_comparison_exp
		$statusComp: Int_comparison_exp
		$xpComp: Boolean_comparison_exp
		$companyComp: String_comparison_exp
		$seminarComp: Boolean_comparison_exp
		$courseComp: Boolean_comparison_exp
		$otherComp: Boolean_comparison_exp
		$mailingComp: Boolean_comparison_exp
		$seniorityComp: Int_comparison_exp
	) {
		users(
			where: {
				role: { _eq: "user" },
				company: {
					_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
				},
				_and: {
					status: $statusComp
					seniority: $seniorityComp
					experience_seminar: $seminarComp
					experience_course: $courseComp
					experience_other: $otherComp
					mailing: $mailingComp
					logs: {
						_or: [
							{ logType: { slug: { _eq: "visited_media_library" } } }
							{ logType: { slug: { _eq: "scenario_nodes" } } }
						]
						_and: [{ created_at: $fromComp }, { created_at: $toComp }]
					}
					experienced: $xpComp
					userOrganisations: { organisation: $idComp }
					company: $companyComp
				},
			}
		) {
			id
			logs(
				order_by: { created_at: asc }
				where: {
					_or: [
						{ logType: { slug: { _eq: "visited_media_library" } } }
						{ logType: { slug: { _eq: "scenario_nodes" } } }
					]
					_and: [{ created_at: $fromComp }, { created_at: $toComp }]
				}
			) {
				logType {
					slug
				}
				created_at
				updated_at
			}
		}
	}
`

//-----------------Active Users-----------------//
const GC_GET_USER_ACTIVITY = gql`
	query ActiveUsersPerWeek(
		$from: timestamptz!
		$to: timestamptz!
		$idComp: uuid_comparison_exp
		$xpComp: Boolean_comparison_exp
		$statusComp: Int_comparison_exp
		$companyComp: String_comparison_exp
		$seminarComp: Boolean_comparison_exp
		$courseComp: Boolean_comparison_exp
		$otherComp: Boolean_comparison_exp
		$mailingComp: Boolean_comparison_exp
		$seniorityComp: Int_comparison_exp
	) {
		users(
			where: {
				role: { _eq: "user" },
				company: {
					_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
				}
				logs: {
					_or: [
						{ logType: { slug: { _eq: "visited_media_library" } } }
						{ logType: { slug: { _eq: "scenario_nodes" } } }
					]
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
				_and: {
					status: $statusComp
					seniority: $seniorityComp
					experience_seminar: $seminarComp
					experience_course: $courseComp
					experience_other: $otherComp
					mailing: $mailingComp
					userOrganisations: { organisation: $idComp }
					experienced: $xpComp
					company: $companyComp
				}
			}
		) {
			id
			logs(
				where: {
					_or: [
						{ logType: { slug: { _eq: "visited_media_library" } } }
						{ logType: { slug: { _eq: "scenario_nodes" } } }
					]
					_and: [{ created_at: { _gte: $from } }, { created_at: { _lte: $to } }]
				}
				order_by: { created_at: asc }
			) {
				created_at
			}
		}
	}
`

const GC_GET_USER_ACTIVITY_BY_ID = gql`
	query UserActivityByID($fromComp: timestamptz_comparison_exp, $toComp: timestamptz_comparison_exp, $id: uuid!) {
		users(
			where: {
				logs: {
					_or: [
						{ logType: { slug: { _eq: "visited_media_library" } } }
						{ logType: { slug: { _eq: "scenario_nodes" } } }
					]
					_and: [{ created_at: $fromComp }, { created_at: $toComp }]
				}
				id: { _eq: $id }
			}
		) {
			logs(
				where: {
					_or: [
						{ logType: { slug: { _eq: "visited_media_library" } } }
						{ logType: { slug: { _eq: "scenario_nodes" } } }
					]
					_and: [{ created_at: $fromComp }, { created_at: $toComp }]
				}
				order_by: { created_at: asc }
			) {
				logType {
					slug
				}
				created_at
				updated_at
			}
		}
	}
`

//-----------------New Users-----------------//
const GC_GET_NEW_USERS_PER_WEEK = gql`
	query NewUsersPerWeek(
		$from: timestamptz!
		$to: timestamptz!
		$xpComp: Boolean_comparison_exp
		$statusComp: Int_comparison_exp
		$companyComp: String_comparison_exp
		$seminarComp: Boolean_comparison_exp
		$courseComp: Boolean_comparison_exp
		$otherComp: Boolean_comparison_exp
		$mailingComp: Boolean_comparison_exp
		$seniorityComp: Int_comparison_exp
		$idComp: uuid_comparison_exp
	) {
		users(
			where: {
				created_at: { _gte: $from, _lte: $to }
				role: { _eq: "user" }
				company: {
					_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
				}
				_and: {
					status: $statusComp
					seniority: $seniorityComp
					experience_seminar: $seminarComp
					experience_course: $courseComp
					experience_other: $otherComp
					mailing: $mailingComp
					experienced: $xpComp
					userOrganisations: { organisation: $idComp }
					company: $companyComp
				}
			}
			order_by: { created_at: asc }
		) {
			id
			created_at
		}
	}
`

//-----------------Finished users-----------------//
const GC_GET_FINISHED_USERS = gql`
	query GetFinishedUsers(
		$idComp: uuid_comparison_exp
		$fromComp: timestamptz_comparison_exp
		$toComp: timestamptz_comparison_exp
		$xpComp: Boolean_comparison_exp
		$statusComp: Int_comparison_exp
		$companyComp: String_comparison_exp
		$seminarComp: Boolean_comparison_exp
		$courseComp: Boolean_comparison_exp
		$otherComp: Boolean_comparison_exp
		$mailingComp: Boolean_comparison_exp
		$seniorityComp: Int_comparison_exp
	) {
		users(
			where: {
				role: { _eq: "user" },
				company: {
					_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
				},
				_and: {
					logs: {
						logType: { slug: { _eq: "scenario_end" } }
						_and: [{ created_at: $fromComp }, { created_at: $toComp }]
					}
					experienced: $xpComp
					experience_seminar: $seminarComp
					experience_course: $courseComp
					experience_other: $otherComp
					mailing: $mailingComp
					status: $statusComp
					seniority: $seniorityComp
					userOrganisations: { organisation: $idComp }
					company: $companyComp
				}
			}
		) {
			logs(where: { logType: { slug: { _eq: "scenario_end" } } }) {
				data_key
			}
			id
		}
	}
`

const GC_GET_ORGA_ONE = gql`
	query GetOrga(
		$id: uuid!
		$fromComp: timestamptz_comparison_exp
		$toComp: timestamptz_comparison_exp
		$statusComp: Int_comparison_exp
		$xpComp: Boolean_comparison_exp
		$companyComp: String_comparison_exp
		$seminarComp: Boolean_comparison_exp
		$courseComp: Boolean_comparison_exp
		$otherComp: Boolean_comparison_exp
		$mailingComp: Boolean_comparison_exp
		$seniorityComp: Int_comparison_exp
	) {
		organisations_by_pk(id: $id) {
			name
			id
			userOrganisations(
				distinct_on: user
				where: {
					users: {
						company: {
							_nin: ["afpols", "AFPOLS", "twn", "TWN", "Afpols", "Twn", "foph", "FOPH"]
						}
						role: { _eq: "user" }
						_and: {
							company: $companyComp
							experience_seminar: $seminarComp
							experience_course: $courseComp
							experience_other: $otherComp
							mailing: $mailingComp
							status: $statusComp
							seniority: $seniorityComp
							experienced: $xpComp
							_and: [{ created_at: $fromComp }, { created_at: $toComp }]
						}
					}
				}
			) {
				users {
					id
					name
					first_name
					company
					email
					experience_other
					experience_seminar
					experience_course
					mailing
					position
					seniority
					status
				}
			}
		}
	}
`

export {
	GC_GET_GAME_RESULTS,
	GC_GET_ORGA_ONE,
	GC_GET_TOTAL,
	GC_GET_SCENARIO_COMPLETION,
	GC_GET_USER_ACTIVITY,
	GC_GET_NEW_USERS_PER_WEEK,
	GC_GET_FINISHED_USERS,
	GC_GET_USER_ACTIVITY_BY_ID,
	GC_GET_GAME_RESULTS_BY_USERID,
	GC_GET_AVERAGE_CO_TIME,
	GC_GET_SCENARIO_COMPLETION_BY_USERID,
	GC_INITALIZE_DASHBOARD_DATA,
}
